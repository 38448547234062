import { defineComponent, PropType } from 'vue'
import './index.scss'
import { CreatorCard, CreatorCardWrapper, GfrTitle, ModuleBase } from '#components'
import { moduleBaseProps } from '@/components/module/base'

interface Card {
  name: string
  desc: string
  images: string[]
  need_entrance: 0 | 1 | 2 // 0 无 ｜ 1 下载 ｜ 2 外链
  entrance_name: string
  entrance_icon: string
  entrance_link: string
  entrance_weight: number
  entrance_display_way: 'left' | 'right'
  status?: 0 | 1 // 0 ｜ 1
}

export default defineComponent({
  name: 'ModuleHomeScreen4',
  props: {
    ...moduleBaseProps,
    title: String,
    subtitle: String,
    cards: {
      type: Array as PropType<Card[]>,
      default: () => []
    }
  },
  setup(props, { slots }) {
    const newCards = computed(() => {
      const activeCards = props.cards?.filter((item) => item.status === 1)
      return activeCards?.sort((a, b) => b.entrance_weight - a.entrance_weight)
    })
    const cardDirection = (way: 'left' | 'right') => {
      return way === 'left' ? 'row' : 'row-reverse'
    }
    const linkInfo = (type: 0 | 1 | 2, name: string, url: string) => {
      if (type) {
        return {
          name,
          url,
          download: type === 1
        }
      }
      return {}
    }
    return () => (
      <ModuleBase name={props.name} class={'module-home-creator-base'}>
        <section class={['module-home-creator']}>
          {props.title && (
            <GfrTitle level={2} class={'com-module-title module-home-creator-title'}>
              {props.title}
            </GfrTitle>
          )}
          {props.subtitle && (
            <GfrTitle level={3} class={'com-module-subtitle module-home-dreams-subtitle'}>
              {props.subtitle}
            </GfrTitle>
          )}
          <CreatorCardWrapper class={'com-module-creator-list'}>
            {newCards.value?.map((card) => (
              <CreatorCard
                title={card.name}
                desc={card.desc}
                images={card.images}
                direction={cardDirection(card.entrance_display_way)}
                {...linkInfo(card.need_entrance, card.entrance_name, card.entrance_link)}
              ></CreatorCard>
            ))}
          </CreatorCardWrapper>
          {slots.default?.()}
        </section>
      </ModuleBase>
    )
  }
})
