import { defineComponent, PropType } from 'vue'
import './index.scss'
export type LinkTypes = 'button' | 'text'
export default defineComponent({
  name: 'GfrLink',
  props: {
    type: String as PropType<LinkTypes>,
    underline: {
      type: Boolean,
      default: true
    },
    link: String,
    disabled: Boolean,
    target: {
      type: String as PropType<'_blank' | '_self' | '_parent' | '_top'>,
      default: '_self'
    }
  },
  setup(props, { slots }) {
    const linkClass = computed(() => {
      return ['gfr-link', `gfr-link-${props.type}`, props.underline && 'underline', props.disabled && 'disabled']
    })
    return () => (
      <a class={linkClass.value} href={props.link} target={props.target}>
        {slots.default?.()}
      </a>
    )
  }
})
