import { defineComponent, PropType } from 'vue'
import {
  CommonDownloadButton,
  GfrGridItem,
  GfrImage,
  GfrLink,
  GfrText,
  GfrTitle,
  Swiper,
  SwiperSlide
} from '#components'
import './index.scss'
export default defineComponent({
  name: 'CreatorCard',
  props: {
    images: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    title: String,
    desc: String,
    direction: {
      type: String as PropType<'row' | 'column' | 'row-reverse' | 'column-reverse'>,
      default: 'row'
    },
    download: Boolean,
    url: String,
    name: String
  },
  emits: ['action'],
  setup(props) {
    const buttonRender = () => {
      if (props.download) {
        return (
          <CommonDownloadButton link={props.url} platform="desktop">
            {props.name}
          </CommonDownloadButton>
        )
      } else {
        return (
          <GfrLink type={'button'} link={props.url} class={'com-creator-link'} target="_blank">
            {props.name}
            <svg class="com-creator-card-outside" viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.2562 0H5.09474V1.41758H8.55117L2 7.7884V10L9.82854 2.68594V6.11798H11.2562V0Z" />
            </svg>
          </GfrLink>
        )
      }
    }
    return () => (
      <GfrGridItem class={['com-creator-card', `com-creator-card-${props.direction}`]}>
        {props.images.length > 1 ? (
          <Swiper
            class={'com-creator-carousel'}
            modules={[SwiperAutoplay, SwiperPagination]}
            slides-per-view={1}
            loop={props.images?.length > 1}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            pagination={{
              clickable: true
            }}
          >
            {props.images?.map((image) => (
              <SwiperSlide class={'com-creator-carousel-slide'}>
                <GfrImage src={image} alt={props.title} fit={'cover'} lazy></GfrImage>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <GfrImage
            class={'com-creator-card-img'}
            src={props.images[0]}
            alt={props.title}
            fit={'cover'}
            lazy
          ></GfrImage>
        )}
        <div class={'com-creator-card-ctx'}>
          <GfrTitle level={3} placement={'left'} class={'com-creator-card-title'}>
            <span class={'com-creator-card-title-text'}>{props.title}</span>
            <svg
              class={'com-creator-card-title-decor'}
              viewBox="0 0 618 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M617.5 0L485.988 0C484.474 0 483.015 0.544042 481.877 1.52332L478.464 4.4456H0.5V6H617.5V5V4V0Z"
                fill="#FFBA00"
              />
            </svg>
          </GfrTitle>
          <GfrText position="left" class={'com-creator-card-desc'}>
            {props.desc}
          </GfrText>
          {props.url && buttonRender()}
        </div>
      </GfrGridItem>
    )
  }
})
