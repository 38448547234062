import { defineComponent, PropType } from 'vue'
import './index.scss'
export default defineComponent({
  name: 'GfrText',
  props: {
    tag: {
      type: String,
      default: 'p'
    },
    position: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'center'
    }
  },
  setup(props, { slots }) {
    return () => h(props.tag, { class: `gfr-text gfr-text-${props.position}` }, { default: () => slots.default?.() })
  }
})
