import { defineComponent } from 'vue'
import { GfrGrid } from '#components'
import './index.scss'
export default defineComponent({
  name: 'CreatorCardWrapper',
  setup(_, { slots }) {
    return () => (
      <GfrGrid class={'com-creator-wrapper'} direction={'column'} gap={30}>
        {slots.default?.()}
      </GfrGrid>
    )
  }
})
